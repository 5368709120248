const HelpWidgetScripts = () => {
  return (
    <>
      <script crossOrigin="anonymous" src="https://cdn.botframework.com/botframework-webchat/latest/webchat.js" />
      <script
        crossOrigin="anonymous"
        src="https://unpkg.com/@azure/storage-blob@10.3.0/browser/azure-storage.blob.min.js"
        integrity="sha384-fsfhtLyVQo3L3Bh73qgQoRR328xEeXnRGdoi53kjo1uectCfAHFfavrBBN2Nkbdf"
      />
      <script type="text/javascript" src="/helpWidget.js" />
    </>
  );
};

export default HelpWidgetScripts;
