import type { HtmlLinkDescriptor } from '@remix-run/react';

const fontLinkAttributes = {
  rel: 'preload',
  as: 'font',
  type: 'font/woff2',
  crossOrigin: 'anonymous',
} as Omit<HtmlLinkDescriptor, 'href'>;

const fontSubsetUrls = [
  '/fonts/Gilroy-Medium-subset.woff2',
  '/fonts/Gilroy-Bold-subset.woff2',
  '/fonts/Gilroy-Extrabold-subset.woff2',
  '/fonts/Gilroy-Black-subset.woff2',
  '/fonts/Gilroy-Semibold-subset.woff2',
];

export const fontSubsetLinks = fontSubsetUrls.map(link => ({
  href: link,
  ...fontLinkAttributes,
}));

const cameraFontAttributes = {
  rel: 'preload',
  as: 'font',
  type: 'font/woff2',
  crossOrigin: 'anonymous',
} as Omit<HtmlLinkDescriptor, 'href'>;

const cameraFontUrls = [
  '/fonts/camera/Bungee-Regular.woff2',
  '/fonts/camera/PermanentMarker-Regular.woff2',
  '/fonts/camera/Lexend-VariableFont_wght.woff2',
  '/fonts/camera/LondrinaSolid-Regular.woff2',
  '/fonts/camera/Funkydori.woff2',
  '/fonts/camera/PressStart2P-Regular.woff2',
  '/fonts/camera/Righteous-Regular.woff2',
  '/fonts/camera/ShareTechMono-Regular.woff2',
  '/fonts/camera/Shrikhand-Regular.woff2',
  '/fonts/camera/YesevaOne-Regular.woff2',
  '/fonts/camera/ZillaSlab-Medium.woff2',
];

export const cameraFontLinks = cameraFontUrls.map(link => ({
  href: link,
  ...cameraFontAttributes,
}));
