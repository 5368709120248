export const keydownFocusHandling = (e: TSFix) => {
  // ignore focus handling for text fields
  if ((e.target && e.target.nodeName === 'TEXTAREA') || e.target.nodeName === 'INPUT') return;

  const KEY_CODES = [
    // Tab
    9,
    // Enter
    13,
    // Esc
    27,
    // Arrows
    37, 38, 39, 40,
  ];
  if (KEY_CODES.indexOf(e.keyCode) > -1) {
    document.body.classList.add('-focusVisible');
  }
};

export const mouseupFocusHandling = () => {
  document.body.classList.remove('-focusVisible');
};
