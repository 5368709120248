import { Button, IcFluentDismiss24Regular } from '@flipgrid/flipkit';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import globalContext from '~/contexts/globalContext';

type Props = {
  hide: boolean;
};

const HelpWidget = ({ hide }: Props) => {
  const { t } = useTranslation();
  const { setHideHelp } = useContext(globalContext);

  return (
    <div className={classNames('helpWidget', 'fk-theme__light', { 'fk-visuallyHidden': hide })}>
      <div className="helpWidget__title background__brand">
        <h1 className="fk-h3 color__white">{t('helpWidget.flipHelp')}</h1>
        <Button
          className="helpWidget__close"
          icon={<IcFluentDismiss24Regular />}
          aria-label={t('helpWidget.close')}
          data-testid="helpWidget__button__close"
          size="36"
          onClick={() => setHideHelp(true)}
        />
      </div>
      <div className="helpWidget__content" id="webchat" role="main" />
    </div>
  );
};

export default HelpWidget;
