import { useLocation } from '@remix-run/react';
import { useState, useEffect } from 'react';

const useNavigationHistory = () => {
  const location = useLocation();
  const [locationStack, setLocationStack] = useState([location.pathname]);

  useEffect(() => {
    setLocationStack(prevHistory => {
      const history = [...prevHistory, location.pathname];
      const maxHistoryLength = 3;

      return history.length > maxHistoryLength ? history.slice(-maxHistoryLength) : history;
    });
  }, [location.pathname]);

  return locationStack;
};

export default useNavigationHistory;
