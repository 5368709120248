import { useMatches } from '@remix-run/react';

import { useAppInsightsInitialiser } from '../../helper/appInsightsInitializerHelper';
import useGetUser from '../useGetUser';

const useAnalytics = () => {
  const [root] = useMatches();
  const { env } = root.data;
  const loggedInUser = useGetUser();
  return useAppInsightsInitialiser(env.CLIENT_TENANT_KEY, env.ENV, loggedInUser);
};

export default useAnalytics;
