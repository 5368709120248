import { useNavigate } from '@remix-run/react';
import { useCallback, useContext, useState } from 'react';

import globalContext from '~/contexts/globalContext';
import { isFlagPresent } from '~/helper/helper';

import type { Dispatch, SetStateAction } from 'react';

type Props = {
  isRecorderActive: boolean;
  setIsRecorderActive: Dispatch<SetStateAction<boolean>>;
  setIsRecorderReactivated: Dispatch<SetStateAction<boolean>>;
  resetRecorderContext: () => void;
};

export const useRecorder = ({
  isRecorderActive,
  resetRecorderContext,
  setIsRecorderActive,
  setIsRecorderReactivated,
}: Props) => {
  const navigate = useNavigate();
  const [recorderProps, setRecorderProps] = useState<TSFix>();
  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const loadRecorder = useCallback(
    (recorderPageProps: TSFix) => {
      if (isDeprecated) {
        return;
      }
      
      if (isRecorderActive) {
        setIsRecorderReactivated(true);
        return;
      }

      // reset any previous recorder data
      resetRecorderContext();

      // load the recorder
      setRecorderProps(recorderPageProps);
      setIsRecorderActive(true);
    },
    // this is deliberately disabled to not prevent multiple recorder instances
    [isDeprecated, isRecorderActive, resetRecorderContext, setIsRecorderActive, setIsRecorderReactivated],
  );

  const hideRecorder = useCallback(
    (shouldRedirectOnHide = true) => {
      if (typeof document !== 'undefined') {
        const recorder = document.querySelector('.responseRecorder__overlay');
        if (recorder) {
          // hack to hide the recorder from the DOM so the video can continue to upload on navigation
          // eventually the async upload logic should be moved here and we can remove this
          recorder.setAttribute('aria-hidden', 'true');
          recorder.setAttribute('hidden', 'true');

          if (shouldRedirectOnHide) {
            const updatedRoute = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
            navigate(updatedRoute);
          }
        }
      }
    },
    [navigate],
  );

  return {
    loadRecorder,
    hideRecorder,
    recorderProps,
  };
};

export default useRecorder;
