import {
  ErrorBoundary as FkErrorBoundary,
  ModalProvider,
  ModalRoot,
  useBreakpoints,
  useEventListener } from
'@flipgrid/flipkit';
// Import all flipkit styles from compiled file
import { json } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches } from
'@remix-run/react';
import classNames from 'classnames';
import { Socket } from 'phoenix';
import { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next';
import { DynamicLinks } from 'remix-utils';

import { FeatureFlag, Theme, ThemeHead } from './components';
import HelpWidget from './components/HelpWidget/HelpWidget';
import HelpWidgetScripts from './components/HelpWidget/HelpWidgetScripts';
import endpoints from './constants/endpoints';
import HandleIds from './constants/handleIds';
import SupportedLanguages from './constants/languages';
import { RailState } from './constants/railStateEnum';
import resourceRoutes from './constants/resourceRoutes';
import GlobalContext from './contexts/globalContext';
import RecorderContext from './contexts/recorderContext';
import { RecorderStatusEnum } from './enums';
import env from './env.server';
import { fontSubsetLinks } from './helper/fonts';
import { logEvent } from './helper/helper';
import { keydownFocusHandling, mouseupFocusHandling } from './helper/keyboard.client';
import { uaDetectServer } from './helper/uaDetect.server';
import useAnalytics from './hooks/analytics/useAnalytics';
import { useConsoleErrorHistory } from './hooks/useConsoleErrorHistory';
import useFetcherWatcher from './hooks/useFetcherWatcher';
import useNavigationHistory from './hooks/useNavigationHistory';
import useRecorder from './hooks/useRecorder';
import i18next from './i18next.server';
import Announcer from '~/components/Utility/Announcer';
import { clearInvalidCookies, featureFlagsCookie, getCookie, inactiveFeatureFlagsCookie } from '~/cookies';
import useGetUser from '~/hooks/useGetUser';
import { getUserPreferencesSession } from '~/services/userPref.server';
import rootIndexStyles from '~/styles/routes/index/rootIndex.css';

import type { SubmittedResponse } from './components/Camera/src/features/CreateResponse/responsesAPI';
import type { ThemesEnum } from '@flipgrid/flipkit';
import type { Segment } from '@onecamera/core';
import type { LinksFunction, LoaderFunction, MetaFunction } from '@remix-run/node';
import type { ApiResponse, FeatureFlag as FeatureFlagType, RouteTyping } from 'types';

export const handle = {
  id: HandleIds.Root,
  i18n: 'main',
  // Translation Key Evaluations
  // common.flip
  title: 'common.flip'
};

export const loader: LoaderFunction = async ({ request }) => {
  const locale = await i18next.getLocale(request);

  const ua = uaDetectServer(request);
  // Get active feature flags in db
  let featureFlagList: FeatureFlagType[] = [];
  const featureFlags = await fetch(endpoints.FEATURES_URL);
  const featureFlagsJson: ApiResponse<FeatureFlagType[]> = await featureFlags.json();
  featureFlagList = featureFlagsJson.data;

  // Get feature flag list from cookie
  const cookie = request.headers.get('Cookie');
  const ffCookie = featureFlagsCookie(request);
  const cookieFlags = await ffCookie.parse(cookie);

  // temporary method, ACCESS_TOKENs with a value of 'undefined' have been causing crashes. We can remove this safely after the issue is gone
  clearInvalidCookies(cookie, request);

  const userPrefSession = await getUserPreferencesSession(request);

  // Get feature flag list from url
  const params = new URL(request.url).searchParams;
  const urlFlags = params && params.get('featureflags') && params.get('featureflags')?.split(',');

  // Combine flags from cookie and url and make a unique set
  let combinedFlags = [...(cookieFlags || []), ...(urlFlags || [])];
  combinedFlags = [...new Set(combinedFlags)];

  const headers = new Headers();

  if (combinedFlags && combinedFlags.length) {
    // Set the cookie value to a combined list of existing cookie flags + url flags
    headers.append('Set-Cookie', await ffCookie.serialize(combinedFlags));

    // If you can Not find the flag in the db flag list
    // then add the flag to the list
    combinedFlags.forEach((flag: string) => {
      if (
      !featureFlagList.find((dbFlag: FeatureFlagType) => {
        return dbFlag && dbFlag.name === flag;
      }))
      {
        featureFlagList.push({ name: flag });
      }
    });
  }

  // Remove flags that are in the featureflagsoff url param
  if (env && env.IS_DEVELOPMENT_ENV) {
    // Get inactive feature flag list from url
    const inactiveUrlFlags = params && params.get('featureflagsoff') && params.get('featureflagsoff')?.split(',');

    // Get inactive feature flag list from cookie
    const ffOffCookie = inactiveFeatureFlagsCookie(request);
    const inactiveCookieFlags = await ffOffCookie.parse(cookie);

    // Combine flags from cookie and url and make a unique set
    let combinedOffFlags = [...(inactiveCookieFlags || []), ...(inactiveUrlFlags || [])];
    combinedOffFlags = [...new Set(combinedOffFlags)];

    if (combinedOffFlags && combinedOffFlags.length) {
      // Set the cookie value to a combined list of existing inactive cookie flags + url flags
      headers.append('Set-Cookie', await ffOffCookie.serialize(combinedOffFlags));

      // If you can find the flag in the db flag list
      // then remove the flag from the list
      combinedOffFlags.forEach((flag) => {
        const index = featureFlagList.findIndex((dbFlag) => {
          return dbFlag && dbFlag.name === flag;
        });
        if (index > -1) {
          featureFlagList.splice(index, 1);
        }
      });
    }
  }

  const isProduction = env.ENV === 'prod';

  const featureFlagClasses = featureFlagList.map((ff) => 'flag-' + ff.name).join(' ');

  return json(
    {
      featureFlags: featureFlagList,
      featureFlagClasses,
      locale,
      env,
      ua,
      userPrefs: userPrefSession.getUserPreferences(),
      isProduction
    },
    {
      headers
    }
  );
};

export const shouldRevalidate = () => {
  return false;
};

export const meta: MetaFunction = () => {
  return {
    title: 'Flip',
    description:
    'Flip is where social and emotional learning happens! The leading video discussion platform for millions of PreK to PhD educators, students, and families.',
    'al:android:app_name': 'Flip.',
    'al:android:package': 'com.vidku.app.flipgrid',
    'fb:app_id': '188763944970062',
    'twitter:card': 'summary',
    'twitter:site': '@MicrosoftFlip',
    'twitter:creator': '@MicrosoftFlip',
    'twitter:description': 'Check out this Flip!',
    'og:image': 'https://static.flip.com/twitter_cards/default-flip-card.jpg',
    'og:image:type': 'image/jpeg',
    'og:image:width': '2400',
    'og:image:height': '800',
    'og:description': 'Check out this Flip',
    'og:site_name': 'Flip'
  };
};

export const ErrorBoundary = ({ error }: {error: Error;}) => {
  const { i18n: i18nTranslation, t } = useTranslation();
  const navHistory = useNavigationHistory();
  const consoleErrorHistory = useConsoleErrorHistory();
  const { isProduction } = useContext(GlobalContext);
  const isAuthenticated = typeof window !== 'undefined' ? !!getCookie('ACCESS_TOKEN', document.cookie) : 'unknown';
  useEventListener('keydown', keydownFocusHandling, true);
  useEventListener('mouseup', mouseupFocusHandling, true);
  const user = useGetUser();
  useEffect(() => {
    logEvent(
      { name: 'ErrorBoundary::Error' },
      {
        accountType: user?.accountType,
        error: error?.message,
        errorName: error?.name,
        errorStack: error?.stack,
        isAuthenticated,
        isCanvasStudentUser: !!user?.canvasStudentUser,
        isGuestUser: user?.guest,
        path: navHistory.join(', '),
        consoleErrors: consoleErrorHistory
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.message, error?.stack, user?.canvasStudentUser]);

  return (
    <html lang={i18nTranslation.language}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>{t('common.flip')}</title>
        <Meta />
        <Links />
        {isProduction && <script type="text/javascript" src="/clarity.js" />}
      </head>
      <body>
        <Scripts />
        <FkErrorBoundary error={error} />
      </body>
    </html>);

};

export const links: LinksFunction = () => {
  return [
  ...fontSubsetLinks,
  { rel: 'preload', href: rootIndexStyles, as: 'style' },
  {
    rel: 'stylesheet',
    href: rootIndexStyles
  },
  {
    rel: 'icon',
    href: 'https://static.flip.com/flavicon.png'
  }];

};

type AppProps = {
  theme?: keyof typeof ThemesEnum;
};

const App = ({ theme }: AppProps) => {
  const data = (useLoaderData() as RouteTyping<'Root'>);
  const { env: envVars, featureFlagClasses, featureFlags, isProduction, locale = SupportedLanguages[0] } = data;
  const { t, i18n: i18nTranslation } = useTranslation();
  const [liveMessage, setLiveMessage] = useState<string | undefined>('');
  const [socket, setSocket] = useState<Socket>();
  const [choseCreate, setChoseCreate] = useState(false);
  const [choseJoin, setChoseJoin] = useState(false);
  const [closedCreate, setClosedCreate] = useState(false);
  const [closedJoin, setClosedJoin] = useState(false);
  const [queuedTourNewGroup, setQueuedTourNewGroup] = useState(false);
  const [closedIntentModal, setClosedIntentModal] = useState(false);
  const [queuedTourNewTopic, setQueuedTourNewTopic] = useState(false);
  const [hideHelp, setHideHelp] = useState(true);
  const breakPoints = useBreakpoints();
  const isDevEnv = envVars?.IS_DEVELOPMENT_ENV;
  const [rail, setRail] = useState(data?.userPrefs?.rail);

  const matches = useMatches();
  const titles = matches.
  map((match) => match.handle?.title).
  filter((item) => {
    return !!item;
  });
  const title = titles?.length > 0 ? titles[titles.length - 1] : '';

  // recorder context
  const [progress, setProgress] = useState(0);
  const [uploadRetryCount, setUploadRetryCount] = useState(0);
  const [recorderSegments, setRecorderSegments] = useState<Segment[]>([]);
  const [thumbnailBlobUrl, setThumbnailBlobUrl] = useState<string | undefined>();
  const [recorderStatus, setRecorderStatus] = useState<RecorderStatusEnum>(RecorderStatusEnum.Idle);
  const [submittedResponse, setSubmittedResponse] = useState<SubmittedResponse>();
  const [uploadStalled, setUploadStalled] = useState(false);
  const [isUploadingAsync, setIsUploadingAsync] = useState(false);
  const [isRecorderActive, setIsRecorderActive] = useState(false);
  const [isRecorderReactivated, setIsRecorderReactivated] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const resetRecorderContext = () => {
    setIsRecorderActive(false);
    setIsRecorderReactivated(false);
    setIsUploadingAsync(false);
    setProgress(0);
    setRecorderSegments([]);
    setRecorderStatus(RecorderStatusEnum.Idle);
    setSubmittedResponse(undefined);
    setThumbnailBlobUrl(undefined);
    setUploadStalled(false);
  };
  const recorder = useRecorder({
    isRecorderActive,
    setIsRecorderActive,
    setIsRecorderReactivated,
    resetRecorderContext
  });

  useFetcherWatcher({
    action: resourceRoutes.userPreferences,
    formDataAction: 'ToggleRail',
    update: (railData?: {success: boolean;value: RailState;}) => {
      if (railData?.value) setRail(railData?.value);
    }
  });

  const announceLiveMessage = useCallback(
    (message?: string) => {
      setLiveMessage(message);
      setTimeout(() => {
        setLiveMessage('');
      }, 4000);
    },
    [setLiveMessage]
  );

  const retryUpload = useCallback(() => {
    setUploadRetryCount(uploadRetryCount + 1);
    setUploadStalled(false);
  }, [setUploadRetryCount, setUploadStalled, uploadRetryCount]);

  useEventListener('keydown', keydownFocusHandling, true);
  useEventListener('mouseup', mouseupFocusHandling, true);

  useChangeLanguage(locale);
  useNavigationHistory();
  useConsoleErrorHistory();

  useEffect(() => {
    // Establish the socket connection for Flip App
    if (envVars?.WEB_SOCKET_URL)
    setSocket(
      new Socket(envVars.WEB_SOCKET_URL, {
        params: {
          client_id: envVars?.WEB_SOCKET_CLIENT_ID
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAnalytics();

  const contextValues = useMemo(
    () => ({
      announceLiveMessage,
      breakPoints,
      choseCreate,
      choseJoin,
      closedCreate,
      closedIntentModal,
      closedJoin,
      featureFlags: featureFlags || [],
      openPopover,
      queuedTourNewGroup,
      queuedTourNewTopic,
      rail,
      isProduction: !!isProduction,
      setChoseCreate,
      setChoseJoin,
      setClosedCreate,
      setClosedIntentModal,
      setClosedJoin,
      setHideHelp,
      setOpenPopover,
      setQueuedTourNewGroup,
      setQueuedTourNewTopic,
      setRail,
      socket
    }),
    [
    announceLiveMessage,
    breakPoints,
    choseCreate,
    choseJoin,
    closedCreate,
    closedIntentModal,
    closedJoin,
    featureFlags,
    isProduction,
    openPopover,
    queuedTourNewGroup,
    queuedTourNewTopic,
    rail,
    socket]

  );

  const recorderContextValues = useMemo(
    () => ({
      ...recorder,
      recorderStatus,
      isRecorderActive,
      isRecorderReactivated,
      isUploadingAsync,
      setIsRecorderActive,
      setIsRecorderReactivated,
      setIsUploadingAsync,
      setRecorderStatus,
      recorderProgress: {
        progress,
        recorderSegments,
        thumbnailBlobUrl,
        stalled: uploadStalled,
        submittedResponse,
        setSubmittedResponse,
        setProgress,
        setRecorderSegments,
        setThumbnailBlobUrl,
        setStalled: setUploadStalled
      },
      reset: resetRecorderContext,
      uploadRetryCount,
      retryUpload
    }),
    [
    isRecorderActive,
    isRecorderReactivated,
    isUploadingAsync,
    progress,
    recorder,
    recorderSegments,
    recorderStatus,
    submittedResponse,
    thumbnailBlobUrl,
    uploadStalled,
    uploadRetryCount,
    retryUpload]

  );

  return (
    <html lang={locale} dir={i18nTranslation.dir()} className={theme ?? ''}>
      <head>
        <title>{t(title) || t('common.flip')}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />

        <DynamicLinks />
        <Meta />
        <Links />
        {isProduction && <script defer type="text/javascript" src="/clarity.js" />}
        <ThemeHead ssrTheme={!!theme} />
      </head>
      <body className={classNames([{ 'rail-expanded': rail === RailState.expanded }, featureFlagClasses])}>
        <RecorderContext.Provider value={recorderContextValues}>
          <GlobalContext.Provider value={contextValues}>
            <ModalProvider>
              <ModalRoot announceLiveMessage={announceLiveMessage} />
              <Outlet />
              <script
                dangerouslySetInnerHTML={{
                  __html: `window.i18n_cacheVersion = ${JSON.stringify(envVars.CLIENT_I18N_CACHE_VERSION)};`
                }} />

            </ModalProvider>

            <Announcer liveMessage={liveMessage} />
            <ScrollRestoration />
            <Scripts />
            {isDevEnv && <LiveReload />}
            <FeatureFlag match="web-helpWidget">
              <HelpWidgetScripts />
              <HelpWidget hide={hideHelp} />
            </FeatureFlag>
          </GlobalContext.Provider>
        </RecorderContext.Provider>
      </body>
    </html>);

};

export default function AppWithTheme() {
  const data = (useLoaderData() as RouteTyping<'Root'>);
  const [theme, setTheme] = useState(data?.userPrefs?.theme);

  useFetcherWatcher({
    action: resourceRoutes.userPreferences,
    formDataAction: 'UpdateTheme',
    update: (themeData?: {success: boolean;value: keyof typeof ThemesEnum;}) => {
      if (themeData?.value) setTheme(themeData?.value);
    }
  });

  return (
    // @ts-expect-error TODO: Fix typing in FlipKit
    <Theme specifiedTheme={theme}>
      <App theme={theme} />
    </Theme>);

}