import { useEventListener } from '@flipgrid/flipkit';
import { useState } from 'react';
import { useHydrated } from 'remix-utils';

type ConsoleError = {
  message: string;
  filename: string;
  lineno: number;
  colno: number;
  timestamp: number;
};

export const useConsoleErrorHistory = (maxErrors: number = 3) => {
  const [errors, setErrors] = useState<ConsoleError[]>([]);
  const isHydrated = useHydrated();

  const errorHandler = (event: ErrorEvent) => {
    if (!isHydrated || !(event instanceof ErrorEvent)) {
      return;
    }

    const { message, filename, lineno, colno } = event;
    const newError = { message, filename, lineno, colno, timestamp: Date.now() };
    setErrors(prevErrors => {
      const newErrorList = [...(prevErrors ?? []), newError];
      return newErrorList.length > maxErrors ? newErrorList.slice(-maxErrors) : newErrorList;
    });
  };

  useEventListener('error', errorHandler);

  return errors;
};
